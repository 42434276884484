import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormatterService } from '../formatter/formatter.service';

@Component({
  selector: 'app-aviso-sistema',
  templateUrl: './aviso-sistema.component.html',
  styleUrls: ['./aviso-sistema.component.scss']
})
export class AvisoSistemaComponent implements OnInit {

  @Input() md: any = null;
  @Input() version: string = '';

  constructor(
    public activeModal: NgbActiveModal,
    public formatter: FormatterService
  ) {}
  
  ngOnInit() {
  }
}
