<div class="modal-header">
    <h4 class="modal-title">¡Aviso de Sistema Lubrikuv!</h4>
</div>
<div class="modal-body">
    <div class="w-100 d-flex justify-content-center mb-4">
        <img style="max-width: 200px;" src="./assets/images/aviso.png" alt="Aviso de Sistema">
    </div>
    <markdown [data]="md"></markdown>
</div>
<div class="modal-footer d-inline-flex flex-column">
    <button class="btn text-white w-100" (click)="activeModal.dismiss('Cross click')">Entendido</button>
    <button class="btn text-white w-100 mt-2" (click)="activeModal.close('Cross click')">Entendido, no volver a mostrar</button>
</div>