<div class="modal-body" [ngClass]="{'bg-primary m-0': formularioFinalizado}">
  <form [formGroup]="form" class="px-md-4" *ngIf="!formularioFinalizado">
    <div class="row mb-3">
      <div class="row background-logo-wrapper">
        <img class="logo" src="../../../../assets/system/logo_sidebar.png" alt="Logo Lubrikuv">
      </div>
    </div>
    <div class="fw-bold fs-3 mt-4 text-center text-center">
      ¿Qué tan probable es que recomiendes Lubrikuv a un colega o amigo?
    </div>
    <div class="text-danger fw-bold fs-4 text-center" *ngIf="form.controls['recomendacion'].errors && form.controls['recomendacion'].touched">
      Debe seleccionar una opción
    </div>
    <div class="d-none">
      <input type="text" autofocus="autofocus" style="display:none" />
    </div>
    <div class="d-flex justify-content-center mb-xl-4 mt-4">
      <div class="d-flex flex-column ">
        <div class="d-flex gap-3 justify-content-center flex-wrap">
          <button *ngFor="let item of recomendaciones;"
            class="boton-recomendacion fw-bold fs-4" role="button" type="button"
            [ngClass]="{'boton-seleccionado': form.value.recomendacion == item}"
            (click)="clickBotonRecomendacion($event, item)"
          >
            {{item}}
          </button>
        </div>
        <div class="d-none d-xl-flex justify-content-between fs-5 mt-2 recomendaciones-guia">
          <div class="margin-poco-probable">
            Poco Probable
          </div>
          <div class="margin-muy-probable">
            Muy Probable
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex d-xl-none justify-content-between fs-5 mt-1 recomendaciones-guia">
      <div>
        Poco Probable
      </div>
      <div>
        Muy Probable
      </div>
    </div>
    <hr>
    <div class="fw-bold fs-3 mt-4 text-center">
      ¿Puedes contarnos más sobre tu experiencia para que nuestro equipo pueda hacerlo mejor la próxima vez?
    </div>
    <div class="text-danger fw-bold fs-4 text-center" *ngIf="form.controls['experiencia'].errors && form.controls['experiencia'].touched">
      Debe rellenar este campo (máximo {{experienciaCaracteres}} caracteres)
    </div>
    <div class="row mb-4 mt-3">
      <div class="col-12">
        <textarea formControlName="experiencia" class="col-12 form-control" name="experiencia" id="experiencia" rows="5" [maxlength]="experienciaCaracteres">
        </textarea>
      </div>
    </div>
    <div class="row justify-content-around">
      <div class="col-12 col-xl-auto mb-3 mb-xl-0">
        <button type="button" class="w-100 btn btn-primary boton-enviar fw-bold fs-4 text-uppercase" (click)="guardar()">
          Enviar
        </button>
      </div>
      <div class="col-12 col-xl-auto">
        <button type="button" class="w-100 btn btn-outline-primary boton-enviar fw-bold fs-4 text-uppercase" (click)="enviarMasAdelante()">
          Enviar más adelante
        </button>
      </div>
    </div>
  </form>
  <div class="finalizado d-flex align-items-center justify-content-center" *ngIf="formularioFinalizado">
    <div class="fw-bold text-white d-flex align-items-center flex-column gap-3">
      <div>
        <i class="fa-regular fa-circle-check"></i>
      </div>
      <div class="fs-1">
        Gracias por su respuesta
      </div>
      <div class="fs-5">
        Valoramos tu opinión. Nos impulsa a seguir mejorando Lubrikuv para ti
      </div>
      <div class="fs-2">
        <button class="btn btn-primary text-uppercase px-md-4" (click)="activeModal.close()">
          Cerrar
        </button>
      </div>
    </div>
  </div>
</div>