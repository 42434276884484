import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/utils/config/config.service';
import { DateService } from 'src/app/utils/date/date.service';
import { SesionService } from 'src/app/utils/sesion/sesion.service';

@Injectable({
  providedIn: 'root'
})
export class EncuestaNPSService {
  INVERSION_CAC = 'inversion-cac';
  FECHA_ENCUESTA_NPS_OMITIDA = 'fecha-encuesta-nps-omitida';
  controller: string = "encuesta-nps/";

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private sesion: SesionService,
    private date: DateService,
  ) { }

  insert(data: any): Observable<any> {
    return this.http.post(this.config.apiUrl + this.controller + 'crear', data, this.sesion.headers);
  }

  reporte(data: any) {
    return this.http.post(this.config.apiUrl + this.controller + 'reporte', data, this.sesion.headers);
  }

  debeMostrarEncuestaNPS(user: any): boolean {
    if (user.rol_id != this.sesion.ROL_ADMINISTRADOR) {
      return false;
    }
    // al menos 2 meses
    if (!user.fecha_inicio_suscripcion || this.date.dateDiffFixed(new Date(), new Date(user.fecha_inicio_suscripcion), 'months') < 2) {
      return false;
    }

    const hoy = new Date();
    const quarterActual = this.date.calcularQuarter(hoy);
    const ultimoMesQuarter = this.date.calcularMesQuarter(quarterActual, 3);
    // que sea ultimo mes del quarter
    if (ultimoMesQuarter != (hoy.getMonth() + 1)) {
      return false;
    }
    // que sea al menos el 15
    if (hoy.getDate() < 15) {
      return false;
    }

    // ha contestado en el q actual
    if (user.fecha_encuesta_nps) {
      const respuestaDate = new Date(user.fecha_encuesta_nps);
      const quarterRespuesta = this.date.calcularQuarter(respuestaDate);
      if (respuestaDate.getFullYear() == hoy.getFullYear() && quarterActual == quarterRespuesta) {
        return false;
      }
    }

    // que no haya hecho skip de la encuesta, (puede omitirla por el dia)
    const fechaOmitida = localStorage.getItem(this.FECHA_ENCUESTA_NPS_OMITIDA)
    if (fechaOmitida) {
      if (fechaOmitida == this.date.formatDateISO8601(new Date())) {
        return false;
      }
    }

    return true;
  }
}
