import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {

  @Input() texto: string = "¡Atención! Esta a punto de eliminar un elemento de la tabla.";
  @Input() textoAceptar: string = "Eliminar elemento";
  @Input() link: string = '';
  @Input() boton_estado: boolean = true;
  @Input() class_boton: string = '';
  @Input() titulo: string = "";
  @Input() detalle: string = "";
  @Input() soloVolver: boolean = false;
  @Input() soloAceptar: boolean = false;

  constructor(public activeModal: NgbActiveModal, private router: Router) {
  }

  ngOnInit(): void { }

  cancelar(): void {
    this.activeModal.dismiss();
  }

  action(): void {
    if (this.boton_estado) {
      this.activeModal.close(true);
    } else {
      this.router.navigate([this.link]);
      this.activeModal.close(true);
    }
  }
}
